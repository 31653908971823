const MobileIcon = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99927 9.42843V9.42273M1.71753 0.866211H6.28101C6.91109 0.866211 7.42188 1.377 7.42188 2.00708V9.99316C7.42188 10.6232 6.91109 11.134 6.28101 11.134H1.71753C1.08744 11.134 0.57666 10.6232 0.57666 9.99316V2.00708C0.57666 1.377 1.08744 0.866211 1.71753 0.866211Z" stroke="#5e5e5e" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default MobileIcon;
