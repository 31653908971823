import PropTypes from "prop-types";

import PlusIcon from "../../../../../components/Icons/PlusIcon";

function WbAddRegistryButton({ buttonText, onClick }) {
  return (
    <button className="wb-add-more-btn" onClick={onClick}>
      <PlusIcon />
      <p>{buttonText}</p>
    </button>
  );
}

WbAddRegistryButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default WbAddRegistryButton;
