import PropTypes from "prop-types";

import WbInput from "../../shared/WbInput";

function WbSetPassword({ password, onPasswordChange, onBlur }) {
  return (
    <WbInput
      label="Guest Password"
      value={password}
      onChange={onPasswordChange}
      onBlur={onBlur}
    />
  );
}

WbSetPassword.propTypes = {
  password: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default WbSetPassword;
