import dayjs from "dayjs";
import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReorderIcon from "../../../../components/Icons/ReorderIcon";
import { UsDateFormat } from "../../../../config/constants";
import {
  capitalizeFirstLetter,
  getImageSrc,
  handleCropComplete,
  handleFileSelect,
} from "../../../../lib/common";
import {
  validateBase64Image,
  validateField,
  ValidationPageTypes,
} from "../../../../lib/weddingWebsiteValidations";
import { PageIds } from "../../../../models/weddingWebsiteTemplates/constants";
import { setHome } from "../../../../store/weddingWebsite/weddingWebsite";
import ImagePopup from "./ImagePopup";
import VisibilityButtons from "./shared/VisibilityButtons";
import WbAccordion from "./shared/WbAccordion";
import WbDateTimePicker from "./shared/WbDateTimePicker";
import WbEditButton from "./shared/WbEditButton";
import WbErrorMessage from "./shared/WbErrorMessage";
import WbInput from "./shared/WbInput";
import WbRemoveButton from "./shared/WbRemoveButton";
import WbTextarea from "./shared/WbTextarea";
import WbUpload from "./shared/WbUpload";
import { getWeddingDate } from '../../../../lib/weddingWebsite';

function WbBasicInfo({
  setBasicInfo,
  showValidation,
  initialIsOpen,
  validationExpand,
  setAccordionStates,
}) {
  let { home, isSinglePageTemplate } = useSelector(
    state => state.weddingWebsite
  );
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.loggedInUser);
  const [announcementVisibility, setAnnouncementVisibility] = useState(
    home?.announcementVisibility ?? true
  );
  const [formData, setFormData] = useState({
    partnerFirstName: home?.partnerFirstName || "",
    partnerLastName: home?.partnerLastName || "",
    firstName: home?.firstName || "",
    lastName: home?.lastName || "",
    weddingDate: getWeddingDate(home?.weddingDate) || null,
    weddingLocation: home?.weddingLocation || "",
    announcement: home?.announcement || "",
    announcementVisibility: home?.announcementVisibility ?? true,
    coverText:home?.coverText,
    coverTitle:home?.coverTitle
  });
  const [errors, setErrors] = useState({});

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSecondImage, setSelectedSecondImage] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [showImagePopup, setShowImagePopup] = useState(false);

  const error  = validateBase64Image(selectedImage);
  const error2 = validateBase64Image(selectedSecondImage);
  const showCropPopup = showImagePopup && (activeImageIndex === 1 ? !error : !error2);

  useEffect(() => {
    let newFormData = { ...formData };

    if (home) {
      // Exclude coverTitle and coverText from the update
      const { coverTitle, coverText, ...restHome } = home;
      newFormData = {
        ...newFormData,
        ...restHome,
      };
      setSelectedImage(getImageSrc(home?.venueImage1));
      setSelectedSecondImage(getImageSrc(home?.venueImage2));
      setAnnouncementVisibility(home?.announcementVisibility);
    } else if (user) {
      newFormData = {
        ...newFormData,
        partnerFirstName: capitalizeFirstLetter(
          user.partnerFirstName || newFormData.partnerFirstName
        ),
        partnerLastName: capitalizeFirstLetter(
          user.partnerLastName || newFormData.partnerLastName
        ),
        firstName: capitalizeFirstLetter(
          user.firstName || newFormData.firstName
        ),
        lastName: capitalizeFirstLetter(user.lastName || newFormData.lastName),
        announcementVisibility:
          announcementVisibility || newFormData.announcementVisibility,
        weddingLocation: `${capitalizeFirstLetter(user.city || "")}, ${
          capitalizeFirstLetter(user.state || "") || newFormData.weddingLocation
        }`,
      };
    }

    setFormData(newFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home]);

  useEffect(() => {
    setBasicInfo({ [PageIds.HOME]: formData });
    validateAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, setBasicInfo]);

  function validateAllFields() {
    const newErrors = {
      partnerFirstName: validateField(
        "partnerFirstName",
        formData.partnerFirstName,
        ValidationPageTypes.BasicInfo
      ),
      partnerLastName: validateField(
        "partnerLastName",
        formData.partnerLastName,
        ValidationPageTypes.BasicInfo
      ),
      partnerName: validateField(
        "partnerName",
        {
          partnerFirstName: formData.partnerFirstName,
          partnerLastName: formData.partnerLastName,
        },
        ValidationPageTypes.BasicInfo
      ),
      bnGName: validateField(
        "bnGName",
        { firstName: formData.firstName, lastName: formData.lastName },
        ValidationPageTypes.BasicInfo
      ),
      firstName: validateField(
        "firstName",
        formData.firstName,
        ValidationPageTypes.BasicInfo
      ),
      lastName: validateField(
        "lastName",
        formData.lastName,
        ValidationPageTypes.BasicInfo
      ),
      weddingDate: validateField(
        "weddingDate",
        formData.weddingDate,
        ValidationPageTypes.BasicInfo
      ),
      weddingLocation: validateField(
        "weddingLocation",
        formData.weddingLocation,
        ValidationPageTypes.BasicInfo
      ),
      announcement: validateField(
        "announcement",
        formData.announcement,
        ValidationPageTypes.BasicInfo
      ),
    };
    setErrors(newErrors);
  }

  const handleInputChange = ({ target: { name, value } }) => {
    let sanitizedValue = value;

    if (
      ["partnerFirstName", "partnerLastName", "firstName", "lastName"].includes(
        name
      )
    ) {
      sanitizedValue = value.replace(/[^a-zA-Z\s-]/g, "");
    } else if (name === "weddingLocation") {
      sanitizedValue = value.replace(/[^a-zA-Z\s,-]/g, "");
    }

    setFormData(prevData => {
      const newData = { ...prevData, [name]: sanitizedValue };
      setBasicInfo({ [PageIds.HOME]: newData });
      return newData;
    });
  };

  const handleVisibilityChange = isVisible => {
    setAnnouncementVisibility(isVisible);
    setFormData(prevData => ({
      ...prevData,
      announcementVisibility: isVisible,
    }));
  };

  function handleDateChange(date) {
    setFormData(prevData => ({
      ...prevData,
      weddingDate: date ? dayjs(date).format(UsDateFormat) : null,
    }));
  }

  const handleSwapInfo = () => {
    setFormData(prevData => ({
      ...prevData,
      partnerFirstName: prevData.firstName,
      partnerLastName: prevData.lastName,
      firstName: prevData.partnerFirstName,
      lastName: prevData.partnerLastName,

    }));
  };

  function showError(firstError, secondError) {
    return showValidation && firstError && secondError;
  }
  const showBngError = showError(errors.firstName, errors.lastName);
  const showPartnerError = showError(
    errors.partnerFirstName,
    errors.partnerLastName
  );

  function handleCropCompleteWrapper(croppedImage) {
    if (activeImageIndex === 1) {
      handleCropComplete(
        croppedImage,
        onImageUpload,
        setSelectedImage,
        setShowImagePopup
      );
    } else if (activeImageIndex === 2) {
      handleCropComplete(
        croppedImage,
        onSecondImageUpload,
        setSelectedSecondImage,
        setShowImagePopup
      );
    }
  }

  const handleFileSelectWrapper = useCallback((event, index) => {
    setActiveImageIndex(index);
    handleFileSelect(
      event,
      index === 1 ? setSelectedImage : setSelectedSecondImage,
      setShowImagePopup,
      true
    );
  }, []);

  function handleImageRemove(index) {
    if (index === 1) {
      onImageRemove();
      setSelectedImage(null);
    } else if (index === 2) {
      onSecondImageRemove();
      setSelectedSecondImage(null);
    }
  }

  function onImageUpload(image) {
    dispatch(setHome({ venueImage1: image }));
  }

  function onImageRemove() {
    dispatch(setHome({ venueImage1: null }));
  }

  function onSecondImageUpload(secondImage) {
    dispatch(setHome({ venueImage2: secondImage }));
  }

  function onSecondImageRemove() {
    dispatch(setHome({ venueImage2: null }));
  }

  return (
    <WbAccordion
      title="Basic Info"
      isCustomElement={false}
      defaultOpen={initialIsOpen}
      validationExpand={validationExpand}
      setAccordionStates={setAccordionStates}
    >
      <div className="panel-sub-heading Mt-0">
        <h4>Your Partner&apos;s Information</h4>
      </div>
      <div className="wb-row-2">
        <WbInput
          label="First Name"
          name="partnerFirstName"
          value={formData.partnerFirstName}
          onChange={handleInputChange}
          error={!errors?.partnerLastName && errors.partnerFirstName}
        />

        <WbInput
          label="Last Name"
          name="partnerLastName"
          value={formData.partnerLastName}
          onChange={handleInputChange}
          error={!errors?.partnerFirstName && errors.partnerLastName}
        />
      </div>
      {showPartnerError && <WbErrorMessage message={errors.partnerName} />}
      <button className="swpap-info-btn" onClick={handleSwapInfo}>
        <ReorderIcon />
        Swap Field
      </button>

      <div className="panel-sub-heading">
        <h4>Your Information</h4>
      </div>
      <div className="wb-row-2">
        <WbInput
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          error={!errors?.lastName && errors.firstName}
        />

        <WbInput
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          error={!errors?.firstName && errors.lastName}
        />
      </div>
      {showBngError && <WbErrorMessage message={errors.bnGName} />}
      <div className="panel-sub-heading">
        <h4>Wedding Date</h4>
      </div>
      <WbDateTimePicker
        label="Wedding Date"
        value={formData.weddingDate}
        onChange={handleDateChange}
        error={errors.weddingDate}
      />
      {showValidation && <WbErrorMessage message={errors.weddingDate} />}

      <div className="panel-sub-heading">
        <h4>Wedding Location</h4>
      </div>
      <WbInput
        label="Wedding Location"
        name="weddingLocation"
        value={formData.weddingLocation}
        onChange={handleInputChange}
        error={errors.weddingLocation}
      />
      {showValidation && <WbErrorMessage message={errors.weddingLocation} />}

      {selectedImage && !error ? (
        <ImageSection
          index={1}
          image={selectedImage}
          onRemove={handleImageRemove}
          setActiveImageIndex={setActiveImageIndex}
          setShowImagePopup={setShowImagePopup}
        />
      ) : (
        <>
          <WbUpload onFileUpload={event => handleFileSelectWrapper(event, 1)} />
          {error ? <WbErrorMessage message={error} /> : null}
        </>
      )}

      {isSinglePageTemplate  &&
        (selectedSecondImage && !error2 ? (
          <ImageSection
            index={2}
            image={selectedSecondImage}
            onRemove={handleImageRemove}
            setActiveImageIndex={setActiveImageIndex}
            setShowImagePopup={setShowImagePopup}
          />
        ) : (
          <>
            <WbUpload onFileUpload={event => handleFileSelectWrapper(event, 2)} />
            {error2 ? <WbErrorMessage message={error2} /> : null}
          </>
        ))}

      <div className="panel-sub-heading">
        <h4>Announcement</h4>
        <VisibilityButtons
          title="Display Announcement"
          onVisibilityChange={handleVisibilityChange}
          visible={announcementVisibility}
        />
      </div>
      <WbTextarea
        label="To Our Guests"
        name="announcement"
        value={formData.announcement}
        onChange={handleInputChange}
        error={errors.announcement}
        disableFormatting={true}
      />
      {showValidation && <WbErrorMessage message={errors.announcement} />}

      {showCropPopup && (
        <ImagePopup
          imageSrc={
            activeImageIndex === 1 ? selectedImage : selectedSecondImage
          }
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
    </WbAccordion>
  );
}

WbBasicInfo.propTypes = {
  setBasicInfo: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
  initialIsOpen: PropTypes.bool,
  validationExpand: PropTypes.bool,
  setAccordionStates: PropTypes.func.isRequired,
};

function ImageSection({ index, image, onRemove, setActiveImageIndex, setShowImagePopup }) {
  return (
    <div className="wb-Edit-photo-sec">
      <div>
        <Image
          src={image}
          alt={`Story image ${index}`}
          width={110}
          height={110}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="wb-edit-remove-button-wrap">
        <WbEditButton
          buttonText="Edit Photo"
          onClick={() => {
            setActiveImageIndex(index);
            setShowImagePopup(true);
          }}
        />
        <WbRemoveButton buttonText="Remove" onClick={() => onRemove(index)} />
      </div>
    </div>
  );
}

ImageSection.propTypes = {
  index: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  setShowImagePopup: PropTypes.func.isRequired,
};

export default WbBasicInfo;
