import PropTypes from "prop-types";
import WbErrorMessage from "./WbErrorMessage";

function WbInput({
  label = "-",
  value = "",
  onChange,
  onBlur = () => {},
  name,
  type = "text",
  placeholder = "",
  className = "",
  required = false,
  disabled = false,
  error = null,
}) {
  return (
      <div className="wb-input-wrapper">
      <div className="wb-input">
        <label htmlFor={name}>{label}</label>
        <div className="wb-form-field">
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            className={`form-control ${className}`}
            required={required}
            disabled={disabled}
          />
        </div>
        </div>
        <WbErrorMessage message={error} />
      </div>
  );
}

WbInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default WbInput;
