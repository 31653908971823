import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { validateField } from "../../../../lib/weddingWebsiteValidations";
import { PageIds } from "../../../../models/weddingWebsiteTemplates/constants";
import WbAccordion from "./shared/WbAccordion";
import WbDateTimePicker from "./shared/WbDateTimePicker";
import WbErrorMessage from "./shared/WbErrorMessage";
import WbInput from "./shared/WbInput";
import WbTextarea from "./shared/WbTextarea";
import { UsDateFormat } from "../../../../config/constants";

function WbEventInformation({
  setBasicInfo,
  showValidation,
  initialIsOpen,
  validationExpand,
  setAccordionStates,
}) {
  const { home } = useSelector(state => state.weddingWebsite);

  const [formData, setFormData] = useState({
    eventName: "",
    date: "",
    startTime: "",
    endTime: "",
    venueName: "",
    attire: "",
    description: "",
    isOpenToAll: true,
  });

  const [errors, setErrors] = useState({});

  const homePageId = PageIds.HOME;

  useEffect(() => {
    if (home) {
      setFormData({
        eventName: home?.eventName || "",
        date: home?.date || "",
        startTime: home?.startTime || "",
        endTime: home?.endTime || "",
        venueName: home.venueName || "",
        attire: home?.attire || "",
        description: home?.description || "",
        isOpenToAll: true,
      });
    }
  }, [home]);

  useEffect(() => {
    setBasicInfo(prevBasicInfo => ({
      ...prevBasicInfo,
      [homePageId]: {
        ...prevBasicInfo[homePageId],
        ...formData,
      },
    }));

    validateAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, setBasicInfo]);

  function validateAllFields() {
    const newErrors = {
      eventName: validateField(
        "eventName",
        formData.eventName,
        "EventInformation"
      ),
      date: validateField("date", formData.date, "EventInformation"),
      startTime: validateField(
        "startTime",
        formData.startTime,
        "EventInformation"
      ),
      endTime: validateField("endTime", formData.endTime, "EventInformation"),
      venueName: validateField(
        "venueName",
        formData.venueName,
        "EventInformation"
      ),
      attire: validateField("attire", formData.attire, "EventInformation"),
      description: validateField(
        "description",
        formData.description,
        "EventInformation"
      ),
    };
    setErrors(newErrors);
  }

  function handleInputChange({ target: { name, value } }) {
    setFormData(prevData => {
      const newData = { ...prevData, [name]: value };
      setBasicInfo(prevBasicInfo => ({
        ...prevBasicInfo,
        [homePageId]: {
          ...prevBasicInfo[homePageId],
          ...newData,
        },
      }));
      return newData;
    });
  }

  function handleRadioChange({ target: { value } }) {
    setFormData(prevData => {
      const newData = { ...prevData, isOpenToAll: value === "openToAll" };
      setBasicInfo(prevBasicInfo => ({
        ...prevBasicInfo,
        [homePageId]: {
          ...prevBasicInfo[homePageId],
          ...newData,
        },
      }));
      return newData;
    });
  }

  function handleDateChange(date) {
    setFormData(prevData => ({
      ...prevData,
      date: date ? dayjs(date).format(UsDateFormat) : null,
    }));
    setBasicInfo(formData);
  }
  function handleTimeChange(time, fieldName) {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: time ? dayjs(time).format("hh:mm A") : null,
    }));
    setBasicInfo(prevFormData => ({
      ...prevFormData,
      [homePageId]: {
        ...prevFormData[homePageId],
        [fieldName]: time ? dayjs(time).format("hh:mm A") : null,
      },
    }));
  }

  return (
    <WbAccordion
      title="Event Information"
      defaultOpen={initialIsOpen}
      validationExpand={validationExpand}
      setAccordionStates={setAccordionStates}
    >
      <div className="event-information-wrap">
        <WbInput
          label="Event Name"
          name="eventName"
          value={formData.eventName}
          onChange={handleInputChange}
        />
        {showValidation && <WbErrorMessage message={errors.eventName} />}

        <WbDateTimePicker
          label="Date"
          name="date"
          value={formData.date}
          onChange={handleDateChange}
        />
        {showValidation && <WbErrorMessage message={errors.date} />}
        <div className="wb-row-2">
          <WbDateTimePicker
            label="Start Time"
            placeholder=""
            name="startTime"
            value={formData.startTime}
            onChange={time => handleTimeChange(time, "startTime")}
            dateDisabled={true}
            className="wb-timepicker"
            error={showValidation ? errors.startTime : ""}
          />

          <WbDateTimePicker
            label="End Time"
            placeholder=""
            name="endTime"
            value={formData.endTime}
            onChange={time => handleTimeChange(time, "endTime")}
            dateDisabled={true}
            className="wb-timepicker right-align"
            error={showValidation ? errors.endTime : ""}
          />
        </div>

        <WbInput
          label="Venue Name"
          name="venueName"
          value={formData.venueName}
          onChange={handleInputChange}
        />
        {showValidation && <WbErrorMessage message={errors.venueName} />}
        <WbInput
          label="Attire"
          name="attire"
          value={formData.attire}
          onChange={handleInputChange}
        />
        {showValidation && <WbErrorMessage message={errors.attire} />}
        <WbTextarea
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          disableFormatting={true}
        />
        {showValidation && <WbErrorMessage message={errors.description} />}
        <div className="event-schedule">
          <p>Event Visibility</p>
          <div className="radio-wrap">
            <div className="wb-radio">
              <input
                type="radio"
                id="openToAll"
                name="eventVisibility"
                value="openToAll"
                checked={formData.isOpenToAll}
                onChange={handleRadioChange}
              />
              <div>
                <label htmlFor="openToAll">Open to all guests</label>
                <h5>This event will appear on your Website&apos;s homepage.</h5>
              </div>
            </div>
            <div className="wb-radio">
              <input
                type="radio"
                id="selectGuests"
                name="eventVisibility"
                value="selectGuests"
                checked={!formData.isOpenToAll}
                onChange={handleRadioChange}
              />
              <div>
                <label htmlFor="selectGuests">Open to select guests</label>
                <h5>This event will only appear to guests when they RSVP.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WbAccordion>
  );
}

WbEventInformation.propTypes = {
  setBasicInfo: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
  initialIsOpen: PropTypes.bool,
  validationExpand: PropTypes.bool,
  setAccordionStates: PropTypes.func.isRequired,
};

export default WbEventInformation;
