import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CopyIconII } from "../../../../../../components/Icons/CopyIcon";
import EditIcon from "../../../../../../components/Icons/EditIcon";
import { Toast } from "../../../../../../components/Toast";
import { validateField } from "../../../../../../lib/weddingWebsiteValidations";
import { copyWebsiteUrl } from "../../../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import WbInput from "../../shared/WbInput";
import WbErrorMessage from "../../shared/WbErrorMessage";

function WebsiteUrlSection({ websiteUrl, setWebsiteUrl }) {
  const { customPageName, isPublished, domain } = useSelector(
    state => state.weddingWebsite
  );
  const [isEditing, setIsEditing] = useState(false);
  const [toast, setToast] = useState({
    type: "",
    message: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    validateWebsiteUrl(websiteUrl);
  }, [websiteUrl]);

  const handleEditClick = () => setIsEditing(true);

  const handleUrlChange = event => {
    setWebsiteUrl(event.target.value);
    validateWebsiteUrl(event.target.value);
  };

  const handleUrlBlur = () => {
    setIsEditing(false);
    validateWebsiteUrl(websiteUrl);
  };

  function validateWebsiteUrl(url) {
    const newError = validateField("customPageName", url, "WebsiteUrl");
    setError(newError);
  }

  function handleCopyLink() {
    if (!isPublished) {
      setToast({
        type: "ERROR",
        message: "Please publish the website first.",
      });
      return;
    }

    copyWebsiteUrl(customPageName, domain);
    setToast({
      type: "SUCCESS",
      message: "Link copied!",
    });
    setTimeout(() => setToast({ type: "", message: "" }), 10);
  }

  function truncateDomainUrl(url) {
    return url.length > 25 ? url.slice(0, 25) + "..." : url;
  }

  const purchasedDomain = domain?.purchased && domain?.domain;
  const domainUrl =
    purchasedDomain || `${window.location.origin}/${websiteUrl}/home`;

  return (
    <>
      <Toast {...toast} />
      <div className="wb-privacy-url">
        <div className="panel-sub-heading">
          <h4>Website Domain Name</h4>
        </div>
        <div className="privacy-url-flex">
          {isEditing ? (
            <div className="url-input-container">
              <WbInput
                label={truncateDomainUrl(domainUrl)}
                value={websiteUrl}
                onChange={handleUrlChange}
                onBlur={handleUrlBlur}
                autoFocus
              />
            </div>
          ) : (
            <h4>
              {truncateDomainUrl(domainUrl)}
              {purchasedDomain ? null : (
                <button onClick={handleEditClick}>
                  <EditIcon />
                </button>
              )}
            </h4>
          )}

          <button className="no-style-btn" onClick={handleCopyLink}>
            <CopyIconII />
          </button>
        </div>
        {error && <WbErrorMessage message={error} />}
      </div>
    </>
  );
}

WebsiteUrlSection.propTypes = {
  websiteUrl: PropTypes.string.isRequired,
  setWebsiteUrl: PropTypes.func.isRequired,
};

export default WebsiteUrlSection;
