import { useState } from "react";
import { useSelector } from "react-redux";

import ComputerIcon from "../../../../components/Icons/ComputerIcon";
import MobileIcon from "../../../../components/Icons/MobileIcon";
import { weddingWebsites } from "../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";

const MOBILE_VIEW = "mobile";
const COMPUTER_VIEW = "computer";

function TemplatePreview() {
  const { selectedTemplateId, previewTemplateId } = useSelector(
    state => state.weddingWebsite
  );

  const [activeView, setActiveView] = useState(COMPUTER_VIEW);

  const templateId = previewTemplateId || selectedTemplateId;

  const template = weddingWebsites.find(template => template.id === templateId);

  return (
    <div className="template-preview">
      <div className="preview-header">
        <div className="view-switch-btn">
          <button
            className={activeView === COMPUTER_VIEW ? "active" : ""}
            onClick={() => setActiveView(COMPUTER_VIEW)}
          >
            <ComputerIcon />
          </button>
          <button
            className={activeView === MOBILE_VIEW ? "active" : ""}
            onClick={() => setActiveView(MOBILE_VIEW)}
          >
            <MobileIcon />
          </button>
        </div>

        <h4>Check responsive views</h4>
      </div>
      <div
        className={`preview-content ${
          activeView === MOBILE_VIEW ? "wb-mobile-view" : ""
        }`}
      >
        <iframe
          title="template-preview"
          id="template-preview"
          src={template?.url ? `/wedding-website/wedding-templates/${template?.url}` : ""}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default TemplatePreview;
