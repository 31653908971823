import React from "react";
import PropTypes from "prop-types";

import { EyeClose, EyeOpen } from "../../../../../components/Icons/EyeIcons";
import ButtonLoader from "../../../../../components/Loader/ButtonLoader";

function VisibilityButtons({
  title,
  visible = true,
  onVisibilityChange,
  loading = false,
}) {
  function toggleVisibility() {
    onVisibilityChange(!visible);
  }
  return (
    <div className="wb-visibility-btns">
      <h6>{title}</h6>
      <div className="view-switch-btn">
        {loading ? (
          <ButtonLoader coloredBorder />
        ) : (
          <button
            className={` ${visible ? "active" : ""}`}
            onClick={toggleVisibility}
            aria-label={visible ? "Hide" : "Show"}
          >
            {visible ? <EyeOpen /> : <EyeClose />}
          </button>
        )}
      </div>
    </div>
  );
}

VisibilityButtons.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onVisibilityChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default VisibilityButtons;
