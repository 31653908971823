import PropTypes from "prop-types";

const WbModalLayout = ({ children, className, onCloseClick = () => { } }) => {
  return (
    <div className={`wb-overlay show ${className}`}>
      <div className="wb-popup">
        <button className="close-popup-btn" onClick={onCloseClick}>X</button>
        {children}
      </div>
    </div>
  );
};

WbModalLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
};
export default WbModalLayout;
