import PropTypes from "prop-types";

function DomainReminderPopup({ onOkClick, onCloseClick }) {
  return (
    <div className="overlay show">
      <div className="popup eventPopup domainreminderPopup">
        <button className="closeBtn" onClick={onCloseClick}></button>
        <h4>Reminder</h4>
        <p>
          <b> Redirect Your Custom Domain After Purchase!</b> Once your domain
          is purchased, you must set up forwarding manually to connect it to
          your wedding website.
        </p>
        <button className="bgBtn" onClick={onOkClick}>
          Okay
        </button>
      </div>
    </div>
  );
}

DomainReminderPopup.propTypes = {
  onOkClick: PropTypes.func,
  onCloseClick: PropTypes.func,
};

export default DomainReminderPopup;
