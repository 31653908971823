import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";

import FullwidthLoader from "../../../../../components/Loader/FullwidthLoder";
import {
  PageIds,
  PageIdToText,
} from "../../../../../models/weddingWebsiteTemplates/constants";
import { processImage } from "../../../../../models/weddingWebsiteTemplates/UploadImageToBucket";
import { saveSelectedTemplateDetails } from "../../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import { updateTemplateValues } from "../../../../../store/weddingWebsite/weddingWebsite";
import SidepanelEditorCard from "../shared/SidepanelEditorCard";
import WbSectionSeprator from "../WbSectionSeprator";

function PageSeprator({ onClick }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasSeparatorImage, setHasSeparatorImage] = useState(null);

  async function onUpdateClick() {
    if (loading) return;

    setLoading(true);

    let data = {
      sectionSeperator: {
        separatorImage: await processImage(hasSeparatorImage),
      },
    };

    dispatch(updateTemplateValues(data));
    await saveSelectedTemplateDetails(data);
    setLoading(false);
  }

  return (
    <>
      {loading && <FullwidthLoader />}

      <SidepanelEditorCard
        title={PageIdToText[PageIds.SECTION_SEPRATOR]}
        onClick={onClick}
        buttons
        onCancelClick={onClick}
        onUpdateClick={onUpdateClick}
      >
        <WbSectionSeprator setHasSeparatorImage={setHasSeparatorImage} />
      </SidepanelEditorCard>
    </>
  );
}

PageSeprator.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PageSeprator;
