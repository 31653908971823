import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { validateField } from "../../../../../../lib/weddingWebsiteValidations";
import { setEditingPassword } from "../../../../../../store/weddingWebsite/weddingWebsite";
import WbAccordion from "../../shared/WbAccordion";
import WbErrorMessage from "../../shared/WbErrorMessage";
import WbToggleSwitch from "../../shared/WbToggleSwitch";
import WbSetPassword from "./WbSetPassword";

function PrivacySection({ privacy, setPrivacy, showValidation }) {
  const weddingWebsite = useSelector(state => state.weddingWebsite);
  const dispatch = useDispatch();

  const { appearInSeachEngine, requirePassword, sitePassword } = privacy;
  const {editingPassword} = weddingWebsite
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitePassword, requirePassword]);

  function validateAllFields() {
    const newErrors = {
      sitePassword: validateField("sitePassword", sitePassword, "Privacy"),
    };
    setErrors(newErrors);
  }

  function handlePasswordChange(e) {
    const newPassword = e.target.value;
    setPrivacy({ sitePassword: newPassword });
    setErrors({
      ...errors,
      sitePassword: validateField("sitePassword", newPassword, "Privacy"),
    });
  }

  return (
    <WbAccordion title="Privacy" className="privacy-accordion">
      <div className="wb-privacy-url">
        <div className="privacy-url-flex">
          <h4>
            Appear in search engines {" "}
            <small>Let guests find your website without a link.</small>
          </h4>
          <WbToggleSwitch
            id="appear-in-search-engine"
            label=""
            isChecked={appearInSeachEngine}
            onChange={() =>
              setPrivacy({ appearInSeachEngine: !appearInSeachEngine })
            }
          />
        </div>
        <div className="privacy-url-flex">
          <h4>
            Set a password{" "}
            <small>Guests will need a password to see your website.</small>
          </h4>
          <WbToggleSwitch
            id="set-a-password"
            label=""
            isChecked={requirePassword}
            onChange={() => setPrivacy({ requirePassword: !requirePassword })}
          />
        </div>
        {requirePassword &&
          (editingPassword ? (
            <WbSetPassword
              password={sitePassword}
              onPasswordChange={handlePasswordChange}
            />
          ) : (
            <div className="privacy-url-flex">
              <h4>Site password: {weddingWebsite.sitePassword}</h4>
              <button
                className="wb-underline-btn blue"
                onClick={() => dispatch(setEditingPassword(true))}
              >
                <span>Edit password</span>
              </button>
            </div>
          ))}
        {showValidation && requirePassword && (
          <WbErrorMessage message={errors.sitePassword} />
        )}
      </div>
    </WbAccordion>
  );
}

PrivacySection.propTypes = {
  privacy: PropTypes.shape({
    appearInSeachEngine: PropTypes.bool.isRequired,
    requirePassword: PropTypes.bool.isRequired,
    sitePassword: PropTypes.string.isRequired,
  }).isRequired,
  setPrivacy: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default PrivacySection;
