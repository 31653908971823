import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { validateBase64Image } from '../../../../lib/weddingWebsiteValidations';
import ImagePopup from "./ImagePopup";
import WbErrorMessage from './shared/WbErrorMessage';

const DEFAULT_SEPARATOR_IMAGE = "/images/wedding-websites/wb-banner.png";

function WbSectionSeperator({ setHasSeparatorImage }) {
    const { sectionSeperator } = useSelector(state => state.weddingWebsite);

    const [isImagePopupVisible, setIsImagePopupVisible] = useState(false);
    const [separatorImage, setSeparatorImage] = useState(sectionSeperator?.separatorImage || DEFAULT_SEPARATOR_IMAGE);
    const [tempSelectedImage, setTempSelectedImage] = useState(null);
    const imageInputRef = useRef(null);

    const error  = validateBase64Image(tempSelectedImage);

    useEffect(() => {
        if (sectionSeperator?.separatorImage) {
            setSeparatorImage(sectionSeperator.separatorImage);
        }
    }, [sectionSeperator?.separatorImage]);

    const handleImageSelect = useCallback((event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            setTempSelectedImage(e.target.result);
            setIsImagePopupVisible(true);
        };
        reader.readAsDataURL(file);
    }, []);

    const handleEditSeparator = useCallback(() => {
        if (separatorImage && separatorImage !== DEFAULT_SEPARATOR_IMAGE) {
            setTempSelectedImage(separatorImage);
            setIsImagePopupVisible(true);
        } else {
            imageInputRef.current?.click();
        }
    }, [separatorImage]);

    const handleCropComplete = (croppedSeparator) => {
        if (!croppedSeparator) return;

        try {
            setHasSeparatorImage(croppedSeparator.blob);  // Pass the Blob for upload
            setSeparatorImage(croppedSeparator.url);  // Use the URL for display
            setIsImagePopupVisible(false);
        } catch (error) {
            console.error("Failed to handle cropped separator image:", error);
        }
    }

    return (
        <div className="wb-cover-photo-content">
            <figure>
                <Image
                    src={separatorImage}
                    alt="Featured Photo"
                    width={100}
                    height={100}
                    layout="responsive"
                    style={{ height: 'auto', width: '100%' }}
                />
            </figure>
            <input
                type="file"
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={handleImageSelect}
                accept="image/*"
            />
            {error ? <WbErrorMessage message={error} /> : null}
            <button className="wb-underline-btn" onClick={handleEditSeparator}>Edit Photo</button>
            <button className="wb-underline-btn ml-10px" onClick={() => imageInputRef.current?.click()}>Replace Photo</button>

            {isImagePopupVisible && !error && (
                <ImagePopup
                    imageSrc={tempSelectedImage || separatorImage}
                    onClose={() => setIsImagePopupVisible(false)}
                    onCropComplete={handleCropComplete}
                />
            )}
        </div>
    );
}

WbSectionSeperator.propTypes = {
    setHasSeparatorImage: PropTypes.func.isRequired,
};

export default WbSectionSeperator;