


export const EyeOpen = () => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00004 1.33331C3.75762 1.33331 1.33337 5.99998 1.33337 5.99998C1.33337 5.99998 3.75762 10.6666 8.00004 10.6666C12.2425 10.6666 14.6667 5.99998 14.6667 5.99998C14.6667 5.99998 12.2425 1.33331 8.00004 1.33331Z" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.00004 7.99998C9.10461 7.99998 10 7.10455 10 5.99998C10 4.89541 9.10461 3.99998 8.00004 3.99998C6.89547 3.99998 6.00004 4.89541 6.00004 5.99998C6.00004 7.10455 6.89547 7.99998 8.00004 7.99998Z" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};



export const EyeClose = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 8.88898C14.2055 7.88783 14.6667 7 14.6667 7C14.6667 7 12.2425 2.33333 8.00004 2.33333C7.77256 2.33333 7.55031 2.34675 7.33337 2.37215C7.10523 2.39886 6.88297 2.43881 6.66671 2.49034M8.00004 5C8.2338 5 8.45819 5.0401 8.66671 5.1138C9.23498 5.31466 9.68538 5.76506 9.88624 6.33333C9.95994 6.54185 10 6.76624 10 7M2.00004 1L14 13M8.00004 9C7.76628 9 7.54189 8.9599 7.33337 8.8862C6.76509 8.68534 6.3147 8.23494 6.11384 7.66667C6.07595 7.55946 6.04694 7.44805 6.02769 7.33333M2.76472 5C2.55922 5.22967 2.37494 5.45494 2.21247 5.66667C1.63528 6.41882 1.33337 7 1.33337 7C1.33337 7 3.75762 11.6667 8.00004 11.6667C8.22752 11.6667 8.44977 11.6532 8.66671 11.6279" stroke="#A9A9A9" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};