import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import FullwidthLoader from "../../../../../../components/Loader/FullwidthLoder";
import { Toast } from "../../../../../../components/Toast";
import {
  customPageAvailable,
  saveSelectedTemplateDetails,
} from "../../../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import { updateTemplateValues } from "../../../../../../store/weddingWebsite/weddingWebsite";
import SidepanelEditorCard from "../../shared/SidepanelEditorCard";
import PrivacySection from "./PrivacySection";
import UpgradeUrlSection from "./UpgradeUrlSection";
import WebsiteUrlSection from "./WebsiteUrlSection";
import { validateAllFields } from "../../../../../../lib/weddingWebsiteValidations";
import HowToRedirect from "./HowToRedirect";

function WbPrivacyUrl({ onClick }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const weddingWebsite = useSelector(state => state.weddingWebsite);
  const { customPageName, home } = weddingWebsite;

  const [websiteUrl, setWebsiteUrl] = useState(customPageName);

  const [privacy, setPrivacy] = useState({
    appearInSeachEngine: weddingWebsite.appearInSeachEngine || false,
    requirePassword: weddingWebsite.requirePassword || false,
    sitePassword: weddingWebsite.sitePassword || "",
  });

  function updatePrivacy(data) {
    setPrivacy(oldState => ({ ...oldState, ...data }));
  }
  const [showValidation, setShowValidation] = useState(false);
  const [saving, setSaving] = useState(false);
  const [toast, setToast] = useState({
    type: "",
    message: "",
  });

  useEffect(() => {
    if (!customPageName) {
      const { firstName = "", partnerFirstName = "" } = home ?? {};

      let customName = `${firstName}${
        partnerFirstName ? `-${partnerFirstName}` : ""
      }`
        .replace(/\s+/g, "")
        .toLowerCase();

      const data = {
        customPageName: customName,
      };
      dispatch(updateTemplateValues(data));
      setWebsiteUrl(customName);
    }
  }, [customPageName, dispatch, home]);

  function checkValidationErrors(data) {
    const privacyUrlErrors = validateAllFields(data, "Privacy");
    const websiteUrlErrors = validateAllFields(data, "WebsiteUrl");
    const mergedErrors = { ...privacyUrlErrors, ...websiteUrlErrors };

    return mergedErrors;
  }

  async function handleUpdateClick() {
    setShowValidation(true);
    const data = { customPageName: websiteUrl, ...privacy, editingPassword: false };
    let errors = checkValidationErrors(data);
    if (saving || Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true);
    setSaving(true);
    if (customPageName !== websiteUrl) {
      const isAvailable = await customPageAvailable(websiteUrl);
      if (!isAvailable) {
        setWebsiteUrl(customPageName);
        setSaving(false);

        setToast({
          type: "ERROR",
          message: "This URL is unavailable. Please choose a different one.",
        });
        setTimeout(() => {
          setToast({
            message: "",
            type: "",
          });
        }, 10);
        return;
      }
    }

    await saveSelectedTemplateDetails(data);
    dispatch(updateTemplateValues(data));
    setLoading(false);
    setSaving(false);
  }

  return (
    <>
    {loading && <FullwidthLoader />}
      <Toast {...toast} />
      <SidepanelEditorCard
        title="Privacy & Domain Name"
        onClick={onClick}
        onCancelClick={onClick}
        onUpdateClick={handleUpdateClick}
        buttons
      >
        <PrivacySection
          privacy={privacy}
          setPrivacy={updatePrivacy}
          showValidation={showValidation}
        />
        <WebsiteUrlSection
          websiteUrl={websiteUrl}
          setWebsiteUrl={setWebsiteUrl}
        />
        <UpgradeUrlSection />
        <HowToRedirect/>
      </SidepanelEditorCard>
    </>
  );
}

WbPrivacyUrl.propTypes = {
  onClick: PropTypes.func,
};

export default WbPrivacyUrl;
