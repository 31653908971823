import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWeddingWebsiteInfo } from "../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import {
  setCurrentTemplate,
  setFetchingData,
} from "../store/weddingWebsite/weddingWebsite";

function useWeddingWebsite() {
  const weddingWebsite = useSelector(state => state.weddingWebsite);
  const dispatch = useDispatch();

  async function fetchWeddingWebsiteInfo() {
    if (weddingWebsite?.fetchingData) {
      return;
    }

    dispatch(setFetchingData(true));

    const weddingWebsiteInfo = await getWeddingWebsiteInfo();
    console.log("weddingWebsiteInfo",weddingWebsiteInfo);
    if (weddingWebsiteInfo) {
      const {
        appearInSeachEngine,
        color,
        customPageName,
        lastPublishedDate,
        requirePassword,
        sections,
        sectionState,
        sitePassword,
        templateId,

        home,
        events,
        pages,
        domain,
        registries,
        isSinglePageTemplate,
        sectionSeperator,
        showManagePages
      } = weddingWebsiteInfo;
      dispatch(
        setCurrentTemplate({
          appearInSeachEngine,
          color,
          customPageName,
          lastPublishedDate,
          requirePassword,
          sections,
          sectionState,
          sitePassword,
          templateId,

          home,
          events,
          pages,
          domain,
          registries,
          isSinglePageTemplate,
          sectionSeperator,
          showManagePages
        })
      );
    }
  }

  useEffect(() => {
    if (!weddingWebsite?.home?.firstName) {
      fetchWeddingWebsiteInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return weddingWebsite;
}

export default useWeddingWebsite;
