import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loggedInUserDetails } from '../store/loggedInUser/userActions'

function useLoggedInUser() {
  const {user} = useSelector(_state => _state.loggedInUser)
  const storeDispatch = useDispatch()

  useEffect(() => {
      storeDispatch(loggedInUserDetails({email: ''}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return user
}

export default useLoggedInUser
