import PropTypes from 'prop-types'
import DateTime from 'react-datetime'
import { useEffect, useRef } from 'react'
import { UsDateFormat } from '../../../../../config/constants';
import WbErrorMessage from './WbErrorMessage';

function WbDateTimePicker({
  label = 'Date',
  placeholder = UsDateFormat,
  onChange,
  value = '',
  className = '',
  dateDisabled = false,
  error = '',
  isValidDate
}) {
  const inputRef = useRef(null)

  function handleDateChange(e) {
    !dateDisabled ? onChange(e.toDate()) : onChange(e)
  }

  useEffect(() => {
    function handleKeyDown(event) {
      event.preventDefault()
    }

    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [])

  return (
    <div className="wb-input-wrapper">
      <div className={`wb-input wb-datepicker ${className}`}>
        <label>{label}</label>
        <div className='wb-form-field'>
          <DateTime
            className='wb-datepicker'
            dateFormat={dateDisabled ? false : UsDateFormat}
            closeOnSelect={true}
            timeFormat={dateDisabled ? 'h:mm A' : false}
            inputProps={{
              placeholder: placeholder,
              ref: inputRef
            }}
            onChange={handleDateChange}
            value={value}
            isValidDate={isValidDate}
          />
        </div>
      </div>

      {error && <WbErrorMessage message={error} />}
    </div>
  )
}

WbDateTimePicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  dateDisabled: PropTypes.bool,
  isValidDate: PropTypes.func,
  error: PropTypes.string
}

export default WbDateTimePicker
