const ArrowDown = () => {
    return (
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08329 0.833313L3.99996 3.74998L6.91663 0.833313" stroke="#5e5e5e" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default ArrowDown;

