import { useRouter } from 'next/router'
import { useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'


import SidepanelEditorCard from '../template-editor/_editor-components/shared/SidepanelEditorCard'
import { registryTypes } from '../../../config/constants'
import LinkRegistry from './LinkRegistry'
import ManageRegistry from './ManageRegistry'
import { saveSelectedTemplateDetails } from '../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates'
import { validateField } from '../../../lib/weddingWebsiteValidations'
import WbErrorMessage from '../template-editor/_editor-components/shared/WbErrorMessage'
import FullwidthLoader from '../../../components/Loader/FullwidthLoder'
import {showToast} from '../../../lib/common'
import { Toast } from '../../../components/Toast'

function RegistryTypeBox({ title, description, buttonText, buttonClass, action, redirectNewPage, redirectForBtn2,onAction,buttonText2, linkRegistry, manageRegistry }) {
  return (
    <div className="registry-type-box">
      <h6>{title}</h6>
      <h4>{description}</h4>
      <div className='wb-action-btns'>
      <button
        className={buttonClass}
        onClick={() => onAction({ action, redirectNewPage,linkRegistry, manageRegistry })}
      >
        {buttonText}
      </button>
     { linkRegistry && <button className="wbBtn wb-borderBtn" onClick={() => onAction({ action, redirectForBtn2,linkRegistry, manageRegistry })}>{buttonText2}</button>}
      </div>
    </div>
  )
}

RegistryTypeBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  action: PropTypes.string,
  redirectNewPage: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  linkRegistry: PropTypes.bool,
  manageRegistry: PropTypes.bool,
  redirectForBtn2: PropTypes.bool,
  buttonText2 :  PropTypes.string
}

RegistryTypeBox.defaultProps = {
  buttonClass: '',
  action: '',
  redirectNewPage: false,
  linkRegistry: false,
  manageRegistry: false
}


function GiftRegistry({ onClick, showLinkRegistry: initialShowLinkRegistry}) {
  const router=useRouter();
  const [showLinkRegistry, setShowLinkRegistry] = useState(initialShowLinkRegistry || false)
  const [showManageRegistry, setShowManageRegistry] = useState(false)
  const [validationLink,setValidationLink] = useState('')
  const [sidePanelHead,setSidePanelHead]=useState('Registry')
  const [error,setError]=useState('')
  const [loading, setLoading] = useState(false);
  const [isSubmited,setIsSubmited]=useState(false);
  const [linkToast, setLinkToast] = useState({
    type: "",
    message: "",
  });

  function backButtonAction() {

    if(showLinkRegistry||showManageRegistry)
    {
      setSidePanelHead('Registry')
      setShowLinkRegistry(false)
      setShowManageRegistry(false)
      setIsSubmited(false)
    }
    else
    {
      onClick();
    }

  }

  const savedRegistries = useSelector(state => state.weddingWebsite.registries || [])

  function handleRegistryAction({ action, redirectNewPage,linkRegistry, manageRegistry }) {

    if (redirectNewPage) {
      router.push(action)
      return
    }
    setShowLinkRegistry(linkRegistry)
    setShowManageRegistry(manageRegistry)
  }

  async function handleUpdateClick() {
    try {
      setIsSubmited(true)
      let validationError='';
      if(!showManageRegistry)
      {
        validationError=validateField("link", validationLink, "Registry")
        setError(validationError);
      }


      if(!validationError ) {
      setLoading(true);
      await saveSelectedTemplateDetails({registries:savedRegistries})
      setLoading(false);
      showToast(setLinkToast, "SUCCESS", `Registry successfully ${showLinkRegistry ? 'linked' : 'unlinked'}`);
      }


    } catch (error) {
      showToast(setLinkToast, "ERROR", `Registry not ${showLinkRegistry ? 'linked' : 'unlinked'}`);
      console.error("Error updating registries:", error)
    }
  }

  useEffect(() => {
    if (initialShowLinkRegistry) {
      setShowLinkRegistry(true);
      setSidePanelHead('Link Registry');
    }
  }, [initialShowLinkRegistry]);

  function renderRegistryContent() {

    if (showManageRegistry) {

      return (
        <ManageRegistry
          setSidePanelHead={setSidePanelHead}
          setShowLinkRegistry={setShowLinkRegistry}
          setShowManageRegistry={setShowManageRegistry}
        />
      );
    }

    if (showLinkRegistry) {

      return (
        <LinkRegistry
          setValidationLink={setValidationLink}
          setSidePanelHead={setSidePanelHead}
        />
      );
    }

    return (
      <div className="gift-registry-wrp">
        {registryTypes.map((registryType) => (
          <RegistryTypeBox
            key={registryType.title}
            {...registryType}
            onAction={handleRegistryAction}
          />
        ))}
      </div>
    );
  }

  return (
    <>
    {loading && <FullwidthLoader />}
    <Toast type={linkToast.type} message={linkToast.message} />
    <SidepanelEditorCard
      title={sidePanelHead}
      onClick={backButtonAction}
      onCancelClick={onClick}
      onUpdateClick={handleUpdateClick}
      buttons={showLinkRegistry || showManageRegistry}
    >
      {renderRegistryContent()}
      { isSubmited && showLinkRegistry && <WbErrorMessage message={error} />}
    </SidepanelEditorCard>
    </>
  )
}

GiftRegistry.propTypes = {
  onClick: PropTypes.func.isRequired,
  showLinkRegistry: PropTypes.bool,
  title: PropTypes.string
}

GiftRegistry.defaultProps = {
  showLinkRegistry: false,
  title: 'Registry'
}


export default GiftRegistry
