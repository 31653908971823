import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackArrow from "../../../components/Icons/BackArrow";

import SentIcon from "../../../components/Icons/SentIcon";
import ViewMyWebsiteIcon from "../../../components/Icons/ViewMyWebsiteIcon";
import { Loader, Toast } from "../../../components/Toast";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import { showToast } from "../../../lib/common";
import {
  CreateWeddingWebsite,
  PageIds,
} from "../../../models/weddingWebsiteTemplates/constants";
import {
  copyWebsiteUrl,
  fetchAndSaveEventDetails,
  getWeddingWebsiteInfo,
  publishWeddingWebsite,
} from "../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import {
  setCurrentTemplate,
  setSelectedPage,
  updateTemplateValues,
} from "../../../store/weddingWebsite/weddingWebsite";
import GiftRegistry from "../registry";
import TemplatePreview from "./_editor-components/TemplatePreview";
import HomeEditor from "./_editor-components/editor-pages/HomeEditor";
import PageSeprator from "./_editor-components/editor-pages/PageSeprator";
import WbPrivacyUrl from "./_editor-components/editor-pages/wb-privacy/PrivacyUrl";
import WbDropdown from "./_editor-components/shared/WbDropdown";
import { CopyIcon } from "../../../components/Icons/CopyIcon";

const CommonPageEditor = dynamic(
  () => import("./_editor-components/editor-pages/CommonPageEditor"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DesignTabContents = dynamic(
  () => import("./_editor-components/DesigntabContents"),
  {
    loading: () => <p>Loading...</p>,
  }
);
const CustomizeTabContents = dynamic(
  () => import("./_editor-components/CustomizetabContents"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const DESIGN_TAB = "design";
const CUSTOMIZE_TAB = "customize";

const tabs = [
  { id: DESIGN_TAB, label: "Design" },
  { id: CUSTOMIZE_TAB, label: "Customize" },
];

const PageToEditor = {
  [PageIds.HOME]: HomeEditor,
  [PageIds.OUR_STORY]: CommonPageEditor,
  [PageIds.PHOTOS]: CommonPageEditor,
  [PageIds.WEDDING_PARTY]: CommonPageEditor,
  [PageIds.RSVP]: CommonPageEditor,
  [PageIds.PRIVACY_URL]: WbPrivacyUrl,
  [PageIds.QA]: CommonPageEditor,
  [PageIds.SECTION_SEPRATOR]: PageSeprator,
  [PageIds.REGISTRY]: GiftRegistry,
  [PageIds.THINGS_TO_DO]: CommonPageEditor,
  [PageIds.TRAVEL]: CommonPageEditor,
  [PageIds.ADD_NEW_PAGE]: CommonPageEditor,
};

function getPageEditor(reordering, selectedPage) {
  if (reordering) {
    return null;
  }

  if (selectedPage) {
    return PageToEditor[selectedPage] ?? CommonPageEditor;
  }

  return null;
}

function TemplateEditor() {
  const dispatch = useDispatch();
  const router = useRouter();

  const user = useLoggedInUser();
  const weddingWebsite = useSelector(state => state.weddingWebsite);
  const {
    selectedTemplateId,
    selectedPage,
    customPageName,
    isPublished,
    domain,
    showManagePages,
  } = weddingWebsite;

  const [edit, setEdit] = useState(showManagePages);
  const [activeTab, setActiveTab] = useState(CUSTOMIZE_TAB);
  const [reordering, setReordering] = useState(showManagePages);
  const [isOpen, setIsOpen] = useState(false);
  const [copyLinkToast, setCopyLinkToast] = useState({
    type: "",
    message: "",
  });
  const [publishToast, setPublishToast] = useState({
    type: "",
    message: "",
  });
  const [isMobileEditorOpen, setIsMobileEditorOpen] = useState(false);
  const [showLinkRegistry, setShowLinkRegistry] = useState(false);

  useEffect(() => {
    const { privacyUrl, registry, linkRegistry } = router.query;
    setShowLinkRegistry(false);
    if (privacyUrl === "true") {
      setEdit(true);
      router.replace(router.pathname, undefined, { shallow: true });
    }

    if (registry === "true") {
      setEdit(true);
      if (linkRegistry === "true") {
        setShowLinkRegistry(true);
      }

      setActiveTab(CUSTOMIZE_TAB);
      dispatch(setSelectedPage(PageIds.REGISTRY));
      router.replace(router.pathname, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, router]);

  function toggleDropDown() {
    setIsOpen(!isOpen);
  }

  function hideShareWebsiteDropDown() {
    if (isOpen) setIsOpen(false);
  }

  async function fetchWeddingWebsiteInfo() {
    const weddingWebsiteInfo = await getWeddingWebsiteInfo();
    if (weddingWebsiteInfo) {
      const {
        appearInSeachEngine,
        color,
        customPageName,
        home,
        events,
        pages = [],
        sections = {},
        sectionState = {},
        templateId,
        requirePassword,
        sitePassword,
        lastPublishedDate,
        domain,
        registries,
        isSinglePageTemplate,
        sectionSeperator,
        showManagePages,
      } = weddingWebsiteInfo;

      dispatch(
        setCurrentTemplate({
          appearInSeachEngine,
          color,
          customPageName,
          home,
          events,
          pages,
          sections,
          sectionState,
          templateId,
          requirePassword,
          sitePassword,
          isPublished: !!lastPublishedDate,
          domain,
          registries,
          isSinglePageTemplate,
          sectionSeperator,
          showManagePages,
        })
      );
    }
  }

  useEffect(() => {
    fetchWeddingWebsiteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId]);

  useEffect(() => {
    if (user?.MainEventDetails?.MyEvents?.length) {
      fetchAndSaveEventDetails(user);
    }
  }, [user]);

  async function handlePublishWebsite() {
    setPublishToast({ type: "LOADING", message: "Publishing Website..." });
    toggleDropDown();

    let isPublished = await publishWeddingWebsite();
    if (!isPublished) {
      // Retry once if first attempt failed
      isPublished = await publishWeddingWebsite();
    }

    if (isPublished) {
      dispatch(updateTemplateValues({ isPublished: true }));
      showToast(setPublishToast, "SUCCESS", "Website published!");
    } else {
      showToast(
        setPublishToast,
        "ERROR",
        "Failed to publish website! Please try after some time."
      );
    }
  }

  function validatePublishedWebsite(message) {
    if (!isPublished) {
      showToast(setCopyLinkToast, "ERROR", message);
      toggleDropDown();
      return false;
    }

    return true;
  }

  function handleCopyLink() {
    if (!validatePublishedWebsite("Please publish the website first.")) {
      return;
    }

    copyWebsiteUrl(customPageName, domain);
    showToast(setCopyLinkToast, "SUCCESS", "Link copied!");
    toggleDropDown();
  }

  function handleViewAsGuestClick(e) {
    if (
      !validatePublishedWebsite(
        "Publish your website to preview it as a guest."
      )
    ) {
      return;
    }

    e.preventDefault();

    const viewURL = copyWebsiteUrl(customPageName, domain);

    if (viewURL) {
      window.open(viewURL, "_blank");
    }
  }

  function handleBackNavigation() {
    router.push("/Dashboard");
  }

  return (
    <div
      className="builder-editor-wrp"
      onClick={hideShareWebsiteDropDown}
      onKeyDown={e => {
        if (e.key === "Enter") {
          hideShareWebsiteDropDown();
        }
      }}
    >
      <Toast type={copyLinkToast.type} message={copyLinkToast.message} />
      <Loader type={publishToast.type} message={publishToast.message} />
      <div className="builder-container">
        <div className="template-editor-heading">
          <div className="template-editor-title">
            <button
              className="wb-back-btn"
              onClick={handleBackNavigation}
              aria-label="Go back to wedding website"
            >
              <BackArrow />
            </button>
            <h2>{CreateWeddingWebsite}</h2>
          </div>
          <div>
            {edit ? null : (
              <button
                className="wbBtn wb-borderBtn ml-auto"
                onClick={() => setEdit(true)}
              >
                EDIT Website
              </button>
            )}

            {edit ? (
              <button
                className="wbBtn wb-bgBtn mob-edit-btn"
                onClick={() => setIsMobileEditorOpen(false)}
              >
                EDIT Website
              </button>
            ) : null}

            <WbDropdown
              btnText="SHARE WEBSITE"
              className="share-website-dropdown"
              isOpen={isOpen}
              toggleDropDown={toggleDropDown}
            >
              <ul>
                <li>
                  <button onClick={handlePublishWebsite}>
                    <SentIcon />
                    <div>
                      Publish Link
                      <small>Make your website public</small>
                    </div>
                  </button>
                </li>
                <li>
                  <button onClick={handleCopyLink}>
                    <CopyIcon />
                    <div>
                      Copy Link
                      <small>
                        Text, DM or email guests a link to your website
                      </small>
                    </div>
                  </button>
                </li>
                <li>
                  <button onClick={handleViewAsGuestClick}>
                    <ViewMyWebsiteIcon />
                    <div>
                      View as Guest
                      <small>Preview the website as a guest.</small>
                    </div>
                  </button>
                </li>
              </ul>
            </WbDropdown>

            {edit ? (
              <button
                className="wbBtn wb-borderBtn close-btn"
                onClick={() => setEdit(false)}
              >
                X
              </button>
            ) : null}
          </div>
        </div>
        <div
          className="template-editor-wrap"
          onClick={hideShareWebsiteDropDown}
          onKeyDown={e => {
            if (e.key === "Enter") {
              hideShareWebsiteDropDown();
            }
          }}
        >
          {edit ? (
            <div
              className={`template-editor-options ${
                isMobileEditorOpen ? "" : "open"
              }`}
            >
              <button
                className="wbBtn wb-borderBtn close-btn"
                onClick={() => setIsMobileEditorOpen(true)}
              >
                X
              </button>
              <div className="editor-tabs-head">
                {tabs.map(tab => (
                  <button
                    key={tab.id}
                    className={activeTab === tab.id ? "active" : ""}
                    onClick={() => {
                      setActiveTab(tab.id);
                      if (activeTab !== tab.id) {
                        dispatch(
                          updateTemplateValues({ previewTemplateId: null })
                        );
                      }
                    }}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              <div className="editor-tabs-contents">
                <Suspense fallback={<div>Loading...</div>}>
                  {activeTab === DESIGN_TAB ? (
                    <DesignTabContents />
                  ) : (
                    <>
                      {selectedPage ? null : (
                        <CustomizeTabContents
                          reordering={reordering}
                          setReordering={setReordering}
                          templateId={selectedTemplateId}
                        />
                      )}
                      {getPageEditor(reordering, selectedPage)
                        ? React.createElement(
                            getPageEditor(reordering, selectedPage),
                            {
                              onClick: () => dispatch(setSelectedPage(null)),
                              showLinkRegistry: showLinkRegistry,
                            }
                          )
                        : null}
                    </>
                  )}
                </Suspense>
              </div>
            </div>
          ) : null}
          <TemplatePreview />
        </div>
      </div>
    </div>
  );
}

export default TemplateEditor;
