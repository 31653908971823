import PropTypes from "prop-types";
import Select from "react-select";

function WbSelectOptions({
  label = "-",
  onChange,
  options = [],
  placeholder = "Select event",
  value,
  disabled = false,
}) {
  return (
    <div className="wb-input-wrapper">
    <div className="wb-input wb-select">
      <label>{label}</label>
      <div className="wb-form-field">
        <Select
          isDisabled={disabled}
          className="wb-select-wrp"
          classNamePrefix="wb-select"
          isSearchable={false}
          menuPlacement="auto"
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          value={value}
        />
      </div>
    </div>
     {/* "error message" */}
    </div>
  );
}

WbSelectOptions.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default WbSelectOptions;
