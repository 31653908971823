import Link from "next/link";
import LinkIcon from "../../../../../../components/Icons/LinkIcon";

function HowToRedirect() {
  return (
    <div className="wb-privacy-url">
      <div className="panel-sub-heading">
        <h4>How to Redirect</h4>
        <Link href="/wedding-website/domain/how-to-setup-redirection">
          <span className="cursor-pointer">
            <LinkIcon />
          </span>
        </Link>
      </div>
      <h4>
        <small>
          Follow these simple steps to link your domain name to your wedding
          website.
        </small>
      </h4>
    </div>
  );
}

export default HowToRedirect;
