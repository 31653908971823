import PropTypes from 'prop-types'
import { useSelector,useDispatch } from 'react-redux'
import { useEffect } from 'react'

import WbAddRegistryButton from '../template-editor/_editor-components/shared/wbAddRegistryButton'

import { removeRegistry } from '../../../store/weddingWebsite/weddingWebsite'


function RegistryItem({ name,link, id, onRemove }) {
    if (!link) return null
    return (
        <div className="registry-flex">
            <div>
                <h6>Registry Name</h6>
                <h4>{name}</h4>
            </div>
            <button className="wb-underline-btn blue" onClick={() => onRemove(id)}>
                <span>Un-Link Registry</span>
            </button>
        </div>
    )
}

RegistryItem.propTypes = {
    link:PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id:PropTypes.string.isRequired,
    onRemove:PropTypes.func.isRequired,
}

function ManageRegistry({ setSidePanelHead= () => {}, setShowLinkRegistry= () => {}, setShowManageRegistry= () => {} }) {
    const savedRegistries = useSelector(state => state.weddingWebsite.registries)
    const dispatch = useDispatch()

    // This effect should be used instead of directly calling setSidePanelHead
    useEffect(() => {
        setSidePanelHead('Manage Registry')
    }, [setSidePanelHead])

    function showLinkRegistry() {
        setShowLinkRegistry(true)
        setShowManageRegistry(false)
    }
    function handleRemoveRegistry(id) {
        dispatch(removeRegistry(id))
    }
    return (
        <>
            <div className="gift-registry-wrp">
                {savedRegistries?.map((item) => (
                    <RegistryItem key={item.id} name={item.name} link={item.link} id={item.id} onRemove={handleRemoveRegistry} />
                ))}
            </div>
            <WbAddRegistryButton
                buttonText="Link Registry"
                onClick={showLinkRegistry}
            />
        </>
    )
}

ManageRegistry.propTypes = {
    setSidePanelHead: PropTypes.func.isRequired,
    setShowLinkRegistry:PropTypes.func.isRequired,
    setShowManageRegistry:PropTypes.func.isRequired
}

export default ManageRegistry