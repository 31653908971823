import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { validateField } from "../../../../lib/weddingWebsiteValidations";
import { PageIds } from "../../../../models/weddingWebsiteTemplates/constants";
import WbAccordion from "./shared/WbAccordion";
import WbErrorMessage from "./shared/WbErrorMessage";
import WbInput from "./shared/WbInput";
import WbTextarea from "./shared/WbTextarea";

function WbHomeCoverText({
  setBasicInfo,
  showValidation,
  initialIsOpen,
  validationExpand,
  setAccordionStates,
}) {
  const { home } = useSelector(state => state.weddingWebsite);
  const [formData, setFormData] = useState({
    coverTitle: "",
    coverText: "",
  });
  const [errors, setErrors] = useState({});
  const homePageId = PageIds.HOME;
  useEffect(() => {
    if (home) {
      setFormData({
        coverTitle: home.coverTitle || "",
        coverText: home.coverText || "",
      });
    }
  }, [home]);

  useEffect(() => {
    setBasicInfo(prevBasicInfo => ({
      ...prevBasicInfo,
      [homePageId]: {
        ...prevBasicInfo[homePageId],
        ...formData,
      },
    }));
    validateAllFields();
  }, [formData, setBasicInfo]);

  function validateAllFields() {
    const newErrors = {
      coverTitle: validateField(
        "coverTitle",
        formData.coverTitle,
        "HomeCoverText"
      ),
      coverText: validateField(
        "coverText",
        formData.coverText,
        "HomeCoverText"
      ),
    };
    setErrors(newErrors);
  }

  function handleInputChange({ target: { name, value } }) {
    setFormData(prevData => {
      const newData = { ...prevData, [name]: value };
      setBasicInfo(prevBasicInfo => ({
        ...prevBasicInfo,
        [homePageId]: {
          ...prevBasicInfo[homePageId],
          ...newData,
        },
      }));
      return newData;
    });
  }

  return (
    <WbAccordion
      title="Cover Text"
      defaultOpen={initialIsOpen}
      validationExpand={validationExpand}
      setAccordionStates={setAccordionStates}
    >
      <div className="wb-text-editor-wrap">
        <WbInput
          label="Cover Title"
          name="coverTitle"
          value={formData.coverTitle}
          onChange={handleInputChange}
        />
        {showValidation && <WbErrorMessage message={errors.coverTitle} />}
        <WbTextarea
          label="Cover Text"
          name="coverText"
          value={formData.coverText}
          onChange={handleInputChange}
        />
        {showValidation && <WbErrorMessage message={errors.coverText} />}
      </div>
    </WbAccordion>
  );
}

WbHomeCoverText.propTypes = {
  setBasicInfo: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
  initialIsOpen: PropTypes.bool,
  validationExpand: PropTypes.bool,
  setAccordionStates: PropTypes.func.isRequired,
};

export default WbHomeCoverText;
