import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { registries as registryOptions } from '../../../models/weddingWebsiteTemplates/constants'
import WbInput from '../template-editor/_editor-components/shared/WbInput'
import WbSelectOptions from '../template-editor/_editor-components/shared/WbSelectOptions'
import { updateTemplateValues } from '../../../store/weddingWebsite/weddingWebsite'

const registrySelectOptions = registryOptions.map(registry => ({
  label: registry.name,
  value: registry.id
}))

function LinkRegistry({ setValidationLink=()=>{}, setSidePanelHead = () => {}}) {
  const dispatch = useDispatch()
  const savedRegistries = useSelector(state => state.weddingWebsite.registries)
  const [registries, setRegistries] = useState(savedRegistries || [])
  const [selectedRegistry, setSelectedRegistry] = useState(null)
  const [registryLink, setRegistryLink] = useState('')

  // This effect should be used instead of directly calling setSidePanelHead
  useEffect(() => {
    setSidePanelHead('Link Registry')
  }, [setSidePanelHead])


  useEffect(() => {
    if (Array.isArray(registries)) {
      const registriesWithLinks = registries.filter(registry => registry.link && registry.link.trim() !== '')
      dispatch(updateTemplateValues({ registries: registriesWithLinks }))
    }
  }, [registries])

  useEffect(() => {
    if (selectedRegistry) {
      const currentRegistry = registries.find(r => r.id === selectedRegistry.value)
      setRegistryLink(currentRegistry ? currentRegistry.link : '')
      setValidationLink(currentRegistry ? currentRegistry.link : '')
    }
  }, [selectedRegistry, registries])

  function handleRegistryChange(selectedOption) {
    setSelectedRegistry(selectedOption)
    setRegistries(prevRegistries => {
      const existingIndex = prevRegistries.findIndex(r => r.id === selectedOption.value)
      if (existingIndex !== -1) {
        return prevRegistries.map((r, index) =>
          index === existingIndex ? { ...r, name: selectedOption.label, link: r.link } : r
        )
      }
      return [...prevRegistries, { id: selectedOption.value, name: selectedOption.label }]
    })
  }

  function handleLinkChange(event) {
    const newLink = event.target.value
    setRegistryLink(newLink)
    setValidationLink(newLink)
    setRegistries(prevRegistries =>
      prevRegistries.map(r =>
        r.id === selectedRegistry.value ? { ...r, link: newLink } : r
      )
    )
  }



  return (
    <>
      <WbSelectOptions
        label="Registry"
        placeholder="Select Registry"
        options={registrySelectOptions}
        value={selectedRegistry}
        onChange={handleRegistryChange}
      />
      <WbInput
        label="Link"
        value={registryLink}
        onChange={handleLinkChange}
        disabled={!selectedRegistry}
      />
      {/* <WbDeletewithText
        text="Delete Registry"
        className="ml-auto"
        onClick={handleDeleteRegistry}
        disabled={!selectedRegistry}
      /> */}
    </>
  )
}

LinkRegistry.propTypes = {
  setValidationLink: PropTypes.func.isRequired,
  setSidePanelHead: PropTypes.func.isRequired
}

export default LinkRegistry
