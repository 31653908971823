
const ReorderIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_343_325)">
                <path d="M11.5 7.5L11.5 4.5C11.5 1.75 10.25 0.5 7.5 0.5L4.5 0.5C1.75 0.5 0.5 1.75 0.5 4.5L0.5 7.5C0.5 10.25 1.75 11.5 4.5 11.5L7.5 11.5C10.25 11.5 11.5 10.25 11.5 7.5ZM4.5 10.5C2.3 10.5 1.5 9.7 1.5 7.5L1.5 4.5C1.5 2.3 2.3 1.5 4.5 1.5L7.5 1.5C9.7 1.5 10.5 2.3 10.5 4.5L10.5 7.5C10.5 9.7 9.7 10.5 7.5 10.5L4.5 10.5Z" fill="#CC0066" />
                <path d="M5.09999 9.0999C5.14999 9.0999 5.19999 9.0499 5.29999 9.0499C5.35965 9.01923 5.41254 8.97686 5.45549 8.92532C5.49844 8.87378 5.53057 8.81412 5.54999 8.7499L5.54999 3.3999C5.53872 3.27108 5.48243 3.15034 5.39099 3.0589C5.29955 2.96746 5.17881 2.91117 5.04999 2.8999C4.91738 2.8999 4.7902 2.95258 4.69643 3.04635C4.60267 3.14012 4.54999 3.26729 4.54999 3.3999L4.54999 7.3499L3.89999 6.6999C3.82445 6.63621 3.73315 6.59405 3.63569 6.57784C3.53822 6.56163 3.43819 6.57197 3.34611 6.60778C3.25402 6.64359 3.17328 6.70355 3.11238 6.78135C3.05147 6.85914 3.01265 6.95191 2.99999 7.0499C2.99788 7.11566 3.01023 7.18108 3.03614 7.24155C3.06206 7.30202 3.10092 7.35608 3.14999 7.3999L4.69999 8.9499L4.84999 9.0499L5.04999 9.0499L5.09999 9.0999Z" fill="#CC0066" />
                <path d="M7.4 8.59995L7.4 4.64995L8.05 5.29995C8.15182 5.39485 8.28582 5.44761 8.425 5.44761C8.56418 5.44761 8.69819 5.39485 8.8 5.29995C8.89162 5.20649 8.94293 5.08083 8.94293 4.94995C8.94293 4.81907 8.89162 4.69342 8.8 4.59995L7.25 3.04995L7.1 2.94995L6.9 2.94995C6.85 2.94995 6.8 2.99995 6.7 2.99995C6.64034 3.03063 6.58745 3.073 6.5445 3.12453C6.50155 3.17607 6.46942 3.23573 6.45 3.29995L6.45 8.59995C6.46127 8.72878 6.51756 8.84951 6.609 8.94095C6.70044 9.03239 6.82118 9.08869 6.95 9.09995C7.07376 9.08751 7.18845 9.02939 7.27166 8.93694C7.35487 8.84448 7.40063 8.72434 7.4 8.59995Z" fill="#CC0066" />
            </g>
            <defs>
                <clipPath id="clip0_343_325">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};
export default ReorderIcon;
