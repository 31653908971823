import PropTypes from "prop-types";

function WbToggleSwitch({
  id = "toggle-switch",
  label = "Toggle",
  isChecked = false,
  onChange,
}) {
  return (
    <div className="wb-toggle-switch-btn">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={onChange}
        aria-label={label}
      />
      <label htmlFor={id} className="toggle-label">
        {label}
      </label>
    </div>
  );
}

WbToggleSwitch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default WbToggleSwitch;
