import PropTypes from 'prop-types';
import { useEffect, useState, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import 'react-quill/dist/quill.snow.css';


import { validateField } from '../../../../../lib/weddingWebsiteValidations';
import WbErrorMessage from './WbErrorMessage';
import { exctractContentFromHtml } from '../../../../../lib/common';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

function WbTextarea({
  label = '-',
  name,
  value,
  onChange,
  onBlur = () => {},
  className = '',
  placeholder = '',
  showValidation = false,
  fieldType = 'TextBlock',
  disableFormatting = false
}) {
  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState(null);

  const validateContent = useCallback((content) => {
    const validationError = validateField(name, content, fieldType);
    setError(validationError);
  }, [name, fieldType]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (showValidation) {
      validateContent(localValue);
    }
  }, [localValue, showValidation, validateContent]);

  const handleChange = useCallback((content, delta, source, editor) => {
    const htmlContent = editor.getHTML();
    const newValue=exctractContentFromHtml(htmlContent)
    if (newValue) {
        setLocalValue(htmlContent);
        onChange({ target: { name, value: htmlContent } });
    }
}, [name, onChange]);

  const handleBlur = useCallback(() => {
    validateContent(localValue);
    onBlur({ target: { name, value: localValue } });
  }, [localValue, name, onBlur, validateContent]);

  const modules = useMemo(() => ({
    toolbar: disableFormatting ? false : {
      container: [
        ['bold', 'italic', 'link'],
        [{ align: ['', 'center', 'right', 'justify'] }],
      ],
    },
  }), [disableFormatting]);

  const formats = disableFormatting ? [] : ['bold', 'italic', 'link', 'align'];

  return (
    <div className="wb-input-wrapper">
    <div className="wb-input wb-text-editor">
      <label htmlFor={name}>{label}</label>
      <div className="wb-form-field">
        {typeof window !== 'undefined' && (
          <ReactQuill
            theme="snow"
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            modules={modules}
            formats={formats}
            className={`editor-content ${className} ${!localValue ? 'empty' : ''} ${error ? 'error' : ''}`.trim()}
          />
        )}
      </div>
    </div>
    {showValidation && error && <WbErrorMessage message={error}/>}
    </div>
  );
}

WbTextarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showValidation: PropTypes.bool,
  fieldType: PropTypes.string,
  disableFormatting: PropTypes.bool // Add this new prop type
};

export default WbTextarea;