import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";

import {
  CropLandscapeRectangle,
  CropPortraitRectangle,
  CropSquare,
} from "../../../../components/Icons/CropShapeIcons";
import PlaceholderIcon from "../../../../components/Icons/PlaceholderIcon";
import RotateIcon from "../../../../components/Icons/RotateIcon";
import { weddingWebsiteCustomValidation } from "../../../../config/constants";
import WbModalLayout from "./shared/WbModalLayout";

const Orientation = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
  SQUARE: "square",
};

function validateImage(file, isLocalImage) {
  if (isLocalImage) {
    return null;
  }

  const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  const minSize = weddingWebsiteCustomValidation.imageMinSize * 1024 * 1024;
  const maxSize = weddingWebsiteCustomValidation.imageMaxSize * 1024 * 1024;

  if (!validTypes.includes(file.type)) {
    return weddingWebsiteCustomValidation.imageValidationMessages.invalidFormat;
  }

  if (file.size < minSize) {
    return weddingWebsiteCustomValidation.imageValidationMessages
      .lessThanMinSize;
  }

  if (file.size > maxSize) {
    return weddingWebsiteCustomValidation.imageValidationMessages
      .greaterThanMaxSize;
  }

  return null; // No error
}

function ImagePopup({ imageSrc, onClose, onCropComplete }) {
  const isLocalImage =
    typeof imageSrc === "string" && imageSrc.startsWith("/images/");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [cropShape, setCropShape] = useState(Orientation.PORTRAIT);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const onCropChange = useCallback(newCrop => {
    setCrop(newCrop);
  }, []);

  const onZoomChange = useCallback(newZoom => {
    setZoom(newZoom);
  }, []);

  const onCropCompleteHandler = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleUpload = useCallback(async () => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const file = new File([blob], "image", { type: blob.type });
      const validationError = validateImage(file, isLocalImage);

      if (validationError) {
        setError(validationError);
        setShowError(true);
      } else {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        onCropComplete(croppedImage);
      }
    } catch (e) {
      console.error(e);
      onCropComplete("");
      setError("An error occurred while processing the image.");
      setShowError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, onCropComplete]);

  const rangeStyle = {
    background: `linear-gradient(to right, #D62C81 0%, #D62C81 ${
      (zoom - 1) * 50
    }%, #CCCCCC ${(zoom - 1) * 50}%, #CCCCCC 100%)`,
  };

  const rotateLeft = () => {
    setRotation(prevRotation => (prevRotation - 90 + 360) % 360);
  };

  const rotateRight = () => {
    setRotation(prevRotation => (prevRotation + 90) % 360);
  };

  const changeCropShape = shape => {
    setCropShape(shape);
  };

  const getCropAspect = () => {
    switch (cropShape) {
      case Orientation.LANDSCAPE:
        return 4 / 3;
      case Orientation.PORTRAIT:
        return 3 / 4;
      case Orientation.SQUARE:
        return 1;
      default:
        return 4 / 3;
    }
  };

  useEffect(() => {
    if (imageSrc) {
      fetch(imageSrc)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "image", { type: blob.type });
          const validationError = validateImage(file, isLocalImage);
          setError(validationError);
        })
        .catch(err => {
          console.error("Error validating image:", err);
          setError("An error occurred while validating the image.");
        });
    }
  }, [imageSrc]);

  return (
    <WbModalLayout className="crop-popup" onCloseClick={onClose}>
      <div className="crop-popup-head">
        <h4>Adjust Photo</h4>
        <div className="crop-action-btns">
          <div className="crop-btn-grp">
            <button className="left-rotate" onClick={rotateLeft}>
              <RotateIcon />
            </button>
            <button className="right-rotate" onClick={rotateRight}>
              <RotateIcon />
            </button>
          </div>
          <h6>Crop:</h6>
          <button
            className={cropShape === Orientation.PORTRAIT ? "active" : ""}
            onClick={() => changeCropShape(Orientation.PORTRAIT)}
          >
            <CropLandscapeRectangle />
          </button>
          <button
            className={cropShape === Orientation.LANDSCAPE ? "active" : ""}
            onClick={() => changeCropShape(Orientation.LANDSCAPE)}
          >
            <CropPortraitRectangle />
          </button>
          <button
            className={cropShape === Orientation.SQUARE ? "active" : ""}
            onClick={() => changeCropShape(Orientation.SQUARE)}
          >
            <CropSquare />
          </button>
        </div>
      </div>

      <div
        className="crop-container"
        style={{ height: "300px", position: "relative" }}
      >
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={getCropAspect()}
          rotation={rotation}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropCompleteHandler}
        />
      </div>

      <div className="wb-ranger">
        <PlaceholderIcon />
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={e => onZoomChange(Number(e.target.value))}
          className="zoom-slider"
          style={rangeStyle}
        />
        <PlaceholderIcon />
      </div>
      {showError && error && (
        <div
          className="error-message"
          style={{ color: "red", marginTop: "10px", textAlign: "center" }}
        >
          {error}
        </div>
      )}
      <div className="button-group">
        <button className="wbBtn wb-borderBtn" onClick={onClose}>
          Discard Photo
        </button>
        <button className="wbBtn wb-bgBtn" onClick={handleUpload}>
          Upload Photo
        </button>
      </div>
    </WbModalLayout>
  );
};

export default ImagePopup;

// Helper function to create a cropped image
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve({
        blob: blob,
        url: URL.createObjectURL(blob),
      });
    }, "image/jpeg");
  });
};

ImagePopup.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
};
