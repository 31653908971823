const LinkIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9434 8.625V12.375C11.9434 12.7065 11.8117 13.0245 11.5772 13.2589C11.3428 13.4933 11.0249 13.625 10.6934 13.625H3.81836C3.48684 13.625 3.1689 13.4933 2.93448 13.2589C2.70006 13.0245 2.56836 12.7065 2.56836 12.375V5.5C2.56836 5.16848 2.70006 4.85054 2.93448 4.61612C3.1689 4.3817 3.48684 4.25 3.81836 4.25H7.56836"
        stroke="#CC0066"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0684 2.375H13.8184V6.125"
        stroke="#CC0066"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94336 9.25L13.8184 2.375"
        stroke="#CC0066"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
