import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ArrowDown from "../../../../../components/Icons/ArrowDown";

function WbAccordion({
  title,
  children,
  defaultOpen = false,
  validationExpand = false,
  setAccordionStates = () => {},
  className=''
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    if (validationExpand) {
      setIsOpen(true);
    }
  }, [validationExpand]);

  const toggleAccordion = () => {
    setAccordionStates(prevState => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = false;
      }
      return updatedState;
    });

    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleAccordion();
    }
  };

  return (
    <div className={`wb-accordion ${className}`}>
      <div
        className="wb-accordion-header"
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-expanded={isOpen}
      >
        <h3>{title}</h3>
        <div className={`wb-accordion-icon ${isOpen ? "open" : ""}`}>
          <ArrowDown />
        </div>
      </div>
      <div className={`wb-accordion-content ${isOpen ? "open" : ""}`}>
        {children}
      </div>
    </div>
  );
}

WbAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  validationExpand: PropTypes.bool,
  setAccordionStates: PropTypes.func,
  className: PropTypes.string,
};

export default WbAccordion;
