export const CopyIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 0C11.438 0 5.5 0 5.5 0C4.44199 0 3.50001 0.970507 3.50001 2.00001L2.922 2.01351C1.8645 2.01351 1 2.9705 1 4V14C1 15.0295 1.942 16 3.00001 16H10.5C11.558 16 12.5 15.0295 12.5 14H13C14.058 14 15 13.0295 15 12V4.01201L11.5 0ZM10.5 15H3.00001C2.47501 15 2.00002 14.5095 2.00002 14V4C2.00002 3.49051 2.42752 3.0165 2.95252 3.0165L3.50001 3.00001V12C3.50001 13.0295 4.44199 14 5.5 14H11.5C11.5 14.5095 11.025 15 10.5 15ZM14 12C14 12.5095 13.525 13 13 13H5.5C4.975 13 4.5 12.5095 4.5 12V2.00001C4.5 1.49052 4.975 1.00002 5.5 1.00002H10.5C10.492 2.15152 10.5 3.01252 10.5 3.01252C10.5 4.05152 11.435 5.00002 12.5 5.00002C12.5 5.00002 13.031 5.00002 14 5.00002V12ZM12.5 4C11.9675 4 11.5 3.03251 11.5 2.51351C11.5 2.51351 11.5 1.9875 11.5 1.0155V1.0145L14 4H12.5ZM11.5 7.00902H7.00001C6.72401 7.00902 6.50001 7.23252 6.50001 7.50851C6.50001 7.78451 6.72401 8.00801 7.00001 8.00801H11.5C11.776 8.00801 12 7.78449 12 7.50851C12 7.23252 11.776 7.00902 11.5 7.00902ZM11.5 9.50651H7.00001C6.72401 9.50651 6.50001 9.73001 6.50001 10.006C6.50001 10.282 6.72401 10.5055 7.00001 10.5055H11.5C11.776 10.5055 12 10.282 12 10.006C12 9.73001 11.776 9.50651 11.5 9.50651Z"
                fill="#E3A914"
            />
        </svg>
    );
};

export const CopyIconII = () => {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.1934 5.625H7.56836C6.878 5.625 6.31836 6.18464 6.31836 6.875V12.5C6.31836 13.1904 6.878 13.75 7.56836 13.75H13.1934C13.8837 13.75 14.4434 13.1904 14.4434 12.5V6.875C14.4434 6.18464 13.8837 5.625 13.1934 5.625Z"
                stroke="#CC0066"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.81836 9.375H3.19336C2.86184 9.375 2.5439 9.2433 2.30948 9.00888C2.07506 8.77446 1.94336 8.45652 1.94336 8.125V2.5C1.94336 2.16848 2.07506 1.85054 2.30948 1.61612C2.5439 1.3817 2.86184 1.25 3.19336 1.25H8.81836C9.14988 1.25 9.46782 1.3817 9.70224 1.61612C9.93666 1.85054 10.0684 2.16848 10.0684 2.5V3.125"
                stroke="#CC0066"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
