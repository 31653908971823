import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FullwidthLoader from "../../../../../components/Loader/FullwidthLoder";
import { validateAllFields } from "../../../../../lib/weddingWebsiteValidations";
import {
  PageIds,
  PageIdToText,
  WebsiteEditorPages,
} from "../../../../../models/weddingWebsiteTemplates/constants";
import { processImage } from "../../../../../models/weddingWebsiteTemplates/UploadImageToBucket";
import {
  FeatureFlags,
  processSections,
  saveSelectedTemplateDetails,
  validateAllCustomFields,
} from "../../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import {
  updatePageSections,
  updateTemplateValues,
} from "../../../../../store/weddingWebsite/weddingWebsite";
import SidepanelEditorCard from "../shared/SidepanelEditorCard";
import WbBasicInfo from "../WbBasicInfo";
import WbCoverPhoto from "../WbCoverPhoto";
import WbEventInformation from "../WbEventInformation";
import WbHomeCoverText from "../WbHomeCoverText";
const AddNewPage = dynamic(() => import("./AddNewPage"), {
  loading: () => <p>Loading...</p>,
});

async function uploadImages(coverImage, venueImage1, venueImage2) {
  const [coverImageUrl, venueImage1Url, venueImage2Url] = await Promise.all([
    processImage(coverImage),
    processImage(venueImage1),
    processImage(venueImage2),
  ]);
  return [coverImageUrl, venueImage1Url, venueImage2Url];
}

function HomeEditor({ onClick }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isHaveCoverImage, setIsHaveCoverImage] = useState(null);
  const [basicInfo, setBasicInfo] = useState({
    [selectedPage]: {
      firstName: "",
      lastName: "",
      weddingDate: "",
      weddingLocation: "",
      announcement: "",
    },
  });
  const [showValidation, setShowValidation] = useState(false);
  const [accordionStates, setAccordionStates] = useState({
    basicInfo: false,
    homeCoverText: false,
    eventInformation: false,
    customElements: false,
  });

  const { sections, sectionState, selectedPage, home } = useSelector(
    state => state.weddingWebsite
  );
  const { venueImage1, venueImage2 } = home;

  function validateCustomFields() {
    const { isValid } = validateAllCustomFields(
      sections,
      sectionState,
      selectedPage
    );
    return isValid;
  }

  async function onUpdateClick() {
    setShowValidation(true);
    if (loading) return;

    const basicInfoErrors = validateAllFields(
      basicInfo[selectedPage],
      "BasicInfo"
    );

    const homeCoverTextErrors = validateAllFields(
      basicInfo[selectedPage],
      "HomeCoverText"
    );

    const eventInformationErrors = validateAllFields(
      basicInfo[selectedPage],
      "EventInformation"
    );

    const allErrors = {
      ...basicInfoErrors,
      ...homeCoverTextErrors,
      ...eventInformationErrors,
    };

    setAccordionStates(prevState => ({
      ...prevState,
      basicInfo: Object.keys(basicInfoErrors).length > 0,
      homeCoverText: Object.keys(homeCoverTextErrors).length > 0,
      eventInformation: Object.keys(eventInformationErrors).length > 0,
    }));

    // Validate Home component first
    if (Object.keys(allErrors).some(key => allErrors[key])) {
      console.warn("validation errors are there !", allErrors);
      return;
    }

    // Validate common component second
    if (!validateCustomFields()) {
      setAccordionStates(prevState => ({
        ...prevState,
        customElements: true,
      }));
      console.warn("custome Validations failed. Please check the errors.");
      return;
    }

    setLoading(true);

    const [coverImageUrl, venueImage1Url, venueImage2Url] = await uploadImages(
      isHaveCoverImage,
      venueImage1,
      venueImage2
    );

    basicInfo[selectedPage] = {
      ...basicInfo[selectedPage],
      coverImage: coverImageUrl,
      venueImage1: venueImage1Url,
      venueImage2: venueImage2Url,
    };

    let data = {
      [selectedPage]: basicInfo[selectedPage],
    };

    if (sections[selectedPage]) {
      const sectionsCopy = [...sections[selectedPage]];
      const sectionStateCopy = [...sectionState[selectedPage]];

      const updatedSectionState = await processSections(
        sectionsCopy,
        sectionStateCopy,
        selectedPage
      );

      data = {
        ...data,
        sections: {
          [selectedPage]: sectionsCopy,
        },
        sectionState: {
          [selectedPage]: updatedSectionState,
        },
      };

      dispatch(
        updatePageSections({
          sections: sectionsCopy,
          sectionState: updatedSectionState,
        })
      );
    }

    dispatch(updateTemplateValues(data));
    await saveSelectedTemplateDetails(data);
    setLoading(false);
  }

  return (
    <>
      {loading && <FullwidthLoader />}

      <SidepanelEditorCard
        title={PageIdToText[PageIds.HOME]}
        onClick={onClick}
        buttons
        onCancelClick={onClick}
        onUpdateClick={onUpdateClick}
      >
        <WbCoverPhoto setIsHaveCoverImage={setIsHaveCoverImage} />
        <WbBasicInfo
          setBasicInfo={setBasicInfo}
          showValidation={showValidation}
          initialIsOpen={accordionStates.basicInfo}
          validationExpand={accordionStates.basicInfo}
          setAccordionStates={setAccordionStates}
        />
        <WbHomeCoverText
          setBasicInfo={setBasicInfo}
          showValidation={showValidation}
          initialIsOpen={accordionStates.homeCoverText}
          validationExpand={accordionStates.homeCoverText}
          setAccordionStates={setAccordionStates}
        />

        {FeatureFlags.showEventInformationInEditor ? (
          <WbEventInformation
            setBasicInfo={setBasicInfo}
            showValidation={showValidation}
            initialIsOpen={accordionStates.eventInformation}
            validationExpand={accordionStates.eventInformation}
            setAccordionStates={setAccordionStates}
          />
        ) : null}

        <AddNewPage
          pageId={selectedPage}
          pageName={WebsiteEditorPages.HOME.text}
          showValidation={showValidation}
          validationExpand={accordionStates.customElements}
          setAccordionStates={setAccordionStates}
        />
      </SidepanelEditorCard>
    </>
  );
}

HomeEditor.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HomeEditor;
