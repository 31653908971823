import { useState } from "react";

import EditIcon from "../../../../../../components/Icons/EditIcon";
import DomainReminderPopup from "../../../../../../components/Popup/DomainReminderPopup";
import useWeddingWebsite from "../../../../../../hooks/useWeddingWebsite";
import { openUrl } from "../../../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import { ResellerPortalUrl } from "../../../../../../models/weddingWebsiteTemplates/constants";

function UpgradeUrlSection() {
  const weddingWebsite = useWeddingWebsite();
  const [showDomainReminderPopup, setShowDomainReminderPopup] = useState(false);

  if (weddingWebsite?.domain?.purchased) {
    return null;
  }

  function handleReminderOkClick() {
    setShowDomainReminderPopup(false);
    openUrl(ResellerPortalUrl, true);
  }

  return (
    <>
      <div className="wb-privacy-url">
        <div className="panel-sub-heading">
          <h4>Customize Your Domain Name</h4>
          <button
            onClick={() => {
              setShowDomainReminderPopup(true);
            }}
            className="no-style-btn"
          >
            <span className="cursor-pointer">
              <EditIcon className="pink-edit-icon" />
            </span>
          </button>
        </div>
        <h4>
          <small>
            Personalize your website with a custom domain name (your own .com,
            .net, or .wedding)
          </small>
        </h4>
      </div>

      {showDomainReminderPopup ? (
        <DomainReminderPopup
          onOkClick={handleReminderOkClick}
          onCloseClick={() => setShowDomainReminderPopup(false)}
        />
      ) : null}
    </>
  );
}

export default UpgradeUrlSection;
