import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import ImagePopup from "./ImagePopup";
import WbAccordion from "./shared/WbAccordion";
import { weddingWebsites } from "../../../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import WbErrorMessage from './shared/WbErrorMessage';
import { validateBase64Image } from '../../../../lib/weddingWebsiteValidations';

function WbCoverPhoto({setIsHaveCoverImage }) {
    let { home, selectedTemplateId }= useSelector(state => state.weddingWebsite);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [coverImage, setCoverImage] = useState(home?.coverImage);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const error  = validateBase64Image(selectedImage);

    useEffect(() => {
        if (selectedTemplateId) {
            const selectedTemplate = weddingWebsites.find(weddingWeb => weddingWeb.id === selectedTemplateId);
            if (selectedTemplate?.defaultCoverImage) {
                setCoverImage(`/images/wedding-websites/${selectedTemplate.defaultCoverImage}`);
            }
        }
    }, [selectedTemplateId]);

    useEffect(() => {
        if (home.coverImage) {
            setIsHaveCoverImage(home.coverImage);
            setCoverImage(home.coverImage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.coverImage]);

    const handleFileSelect = useCallback((event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            setSelectedImage(e.target.result);
            setShowImagePopup(true);
        };
        reader.readAsDataURL(file);
    }, []);

    const handleEditPhoto = useCallback(() => {
        if (coverImage) {
            setSelectedImage(coverImage);
            setShowImagePopup(true);
        } else {
            fileInputRef.current?.click();
        }
    }, [coverImage]);

    const handleCropComplete = (croppedImage) => {
        if (!croppedImage) return;

        try {
            setIsHaveCoverImage(croppedImage.blob);  // Pass the Blob for upload
            setCoverImage(croppedImage.url);  // Use the URL for display
            setShowImagePopup(false);
        } catch (error) {
            console.error("Failed to handle cropped image:", error);
        }
    }

    return (
        <WbAccordion title="Cover photo">
            <div className="wb-cover-photo-content">
                <figure>
                    <Image
                        src={coverImage}
                        alt="Cover photo"
                        width={100}
                        height={100}
                        layout="responsive"
                        style={{ height: 'auto', width: '100%' }}
                    />
                </figure>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept="image/*"
                />
                {error ? <WbErrorMessage message={error} /> : null}
                <button className="wb-underline-btn" onClick={handleEditPhoto}>Edit Photo</button>
                <button className="wb-underline-btn ml-10px" onClick={() => fileInputRef.current?.click()}>Replace Photo</button>

                {showImagePopup && !error && (
                    <ImagePopup
                        imageSrc={selectedImage || coverImage}
                        onClose={() => setShowImagePopup(false)}
                        onCropComplete={handleCropComplete}
                    />
                )}
            </div>
        </WbAccordion>
    );
}

WbCoverPhoto.propTypes = {
    setIsHaveCoverImage: PropTypes.func.isRequired,
};

export default WbCoverPhoto;
