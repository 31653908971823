import Logo from "../Logo/Logo";

function FullwidthLoader() {
  return (
    <div className="loaderContainer-Desiweds">
      <div className="loader-wrapper">
        <Logo />
      </div>
    </div>
  );
}

export default FullwidthLoader;
