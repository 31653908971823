import React from 'react'

function SentIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.44139 8.55867L1.40939 6.54801C1.06405 6.43267 0.831387 6.10867 0.833387 5.74467C0.835387 5.38067 1.07072 5.05867 1.41739 4.94734L14.0661 0.874005C14.3667 0.777338 14.6967 0.856672 14.9201 1.08001C15.1434 1.30334 15.2227 1.63334 15.1261 1.93401L11.0527 14.5827C10.9414 14.9293 10.6194 15.1647 10.2554 15.1667C9.89139 15.1687 9.56739 14.936 9.45205 14.5907L7.44139 8.55867ZM2.18205 5.75134L7.99472 7.68934C8.14405 7.73867 8.26139 7.85601 8.31072 8.00534L10.2487 13.818L14.0801 1.92001L2.18205 5.75134Z" fill="#E3A914" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.19008 8.51656C7.99541 8.71189 7.67808 8.71189 7.48341 8.51656C7.28808 8.32189 7.28808 8.00456 7.48341 7.80989L14.0174 1.27589C14.2121 1.08056 14.5294 1.08056 14.7241 1.27589C14.9194 1.47056 14.9194 1.78789 14.7241 1.98256L8.19008 8.51656Z" fill="#E3A914" />
        </svg>

    )
}

export default SentIcon