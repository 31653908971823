import PropTypes from "prop-types";
import React from "react";

import ArrowDown from "../../../../../components/Icons/ArrowDown";

const WbDropdown = ({
  className = "",
  btnText = "Button",
  children,
  isOpen = false,
  toggleDropDown,
}) => {
  return (
    <div className={`wb-dropdown ${className}`}>
      <button className="wbBtn wb-bgBtn" onClick={toggleDropDown}>
        {btnText}{" "}
        <span className={`arrow ${isOpen ? "rotate" : ""}`}>
          <ArrowDown />
        </span>
      </button>
      {isOpen && <div className="wb-dropdwon-content">{children}</div>}
    </div>
  );
};

WbDropdown.propTypes = {
  className: PropTypes.string,
  btnText: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  toggleDropDown: PropTypes.func,
};

export default WbDropdown;
